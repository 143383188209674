 ._1fhgRH {
    display: block;
    width: 100%;
    background: #fff;
    padding-bottom: 1px;
}.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bs-heading-color);
}
.list-group-flush {
    border-radius: 0;
}
.list-group {
    --bs-list-group-color: var(--bs-body-color);
    --bs-list-group-bg: var(--bs-body-bg);
    --bs-list-group-border-color: var(--bs-border-color);
    --bs-list-group-border-width: var(--bs-border-width);
    --bs-list-group-border-radius: var(--bs-border-radius);
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: var(--bs-secondary-color);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
    --bs-list-group-action-active-color: var(--bs-body-color);
    --bs-list-group-action-active-bg: var(--bs-secondary-bg);
    --bs-list-group-disabled-color: var(--bs-secondary-color);
    --bs-list-group-disabled-bg: var(--bs-body-bg);
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #0d6efd;
    --bs-list-group-active-border-color: #0d6efd;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius);
}
.list-group {
    text-decoration: none;
}
.flex {
    display: flex !important;
}
.recommended-product img {
    width: 75px;
    height: auto;
}

img, svg {
    vertical-align: middle;
}.flex {
    display: flex !important;
}

.recommended-product {
    display: flex;
    flex-direction: row;
    gap:20px;
    border-top: 1px dashed #eee;
    border-bottom: 1px dashed #eee;

    align-items: center;
}.recommended-product span.discount {
    color: #008c00;
}.recommended-product span.mrp {
    color: #878787;
}
.recommended-product span.strike {
    text-decoration: line-through;
}
.price {
    gap: 10px;
}
.product-price-list {
    display: flex;
    justify-content: space-between;
}.product-price-list.total {
    border-top: 1px dashed #eee;
}.product-price-list.saved-div {
    border-top: 1px solid #eee;
    font-size: 13px;
}.product-price-list .text-success {
    color: #069e06 !important;
}.sefty-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f3f6;
    padding: 15px 0;
    font-size: 14px;
    margin-bottom: 100px;
}

.sefty-banner .sefty-img {
    object-fit: contain;
    display: flex;
    position: relative;
    width: 45px;
    height: 35px;
}.sefty-banner .sefty-txt {
    color: #717478;
    font-weight: 600;
    letter-spacing: 0;
    width: 230.822px;
}.button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: -4px 3px 7px #bdbbbb;
}.footer-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
}.btn-continue {
    width: 170px !important;
    height: 40px !important;
}.product-page-buy {
    width: 100%;
    height: 50px;
    border: 1px solid #ffc200;
}.buynow-button, .buynow-button-white {
    display: block;
    color: #000;
    text-align: center;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    border: 1px solid #ffc200;
    font-size: 14px;
    padding: 6px 9px;
}
.mrp {
    text-decoration: line-through;
    color: #9a9a9a;
    margin: 0 5px;
}
.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba((255,255,255), (1)) !important;
}
.container-progress{
    border-top: 1px rgba(128, 128, 128, 0.418) solid;
    border-radius: 5px;
    padding-top: 5px;
    
}