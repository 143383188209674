.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    border: 1px solid  red;
    background: #677bc4;
    width: 200px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.modal-content p {
    color: white;
    margin-bottom: 20px;
}

.modal-content button {
    margin: 0 10px;
    background-color: red;
    color: white;
    font-weight: 600;
}
#confirm
{
    background-color: green;
    color: white;
    font-weight: 600;
}
