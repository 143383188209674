.products{
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
}

.products .plink
{   width: 50%;
    text-decoration: none;
    flex: 0 0 auto;
    margin: auto;
}
.products .plink .outerDiv{
    position: relative;
    overflow: hidden;
    padding: 16px 8px;
    display: block;
    border-top:2px solid #f0f0f0 ;
    border-left: 3px solid #f0f0f0;
    border-right: 3px solid #f0f0f0;

    background: #fff;
  
    display: flex;
    flex-direction: column;
    gap: .1rem;
}

.products .plink .outerDiv .image img {
    width: 165px;
    height: 165px;
    object-fit: contain;
  }
  
  .products .plink .outerDiv .productName{
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-align: left;
  margin-top: 15px;
  font-size: 14px;
  color: rgb(0, 113, 133);
  font-weight: 700;
}


.products .plink .outerDiv .discount {
font-size: 14px;
color: rgb(56, 142, 60);
margin-top: 4px;
margin-bottom: 4px;
text-align: left;}
/* styles.css */
.products .plink .outerDiv .discount .mrp {
    color: rgb(154, 154, 154);
    margin: 0px 5px;
    text-decoration: line-through;
}

.products .plink .outerDiv .price{
    display: flex;
    margin-bottom: 14px;
    gap: 5px;

}
.products .plink .outerDiv .price img{
    max-width: 74px;
}
/* styles.css */
.selling-price {
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 16px;
}

.products .plink .outerDiv .limitedDeal{
    background: rgb(255, 194, 99);
    color: rgb(0, 0, 0);
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent; /* Replaced with border width and color */
    border-radius: 0.375rem; /* Replaced with border radius */
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.2rem 1rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    border-width: 1px; /* Replaced with border width */
    border-radius: 0.375rem; /* Replaced with border radius */
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15), 0 1px 1px rgba(0,0,0,.075); /* Replaced with box shadow */
    opacity: 1; /* Replaced with disabled opacity */
    margin-bottom: 7px;

}

.products .plink .outerDiv .freedelivery{
    font-size: 12px;
    --bs-text-opacity: 1;
    text-align: center;
    color: grey;
}