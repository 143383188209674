/* Login.css */

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #3700b3;
}

.login-container {
    background-color: #1f1f1f;
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
}

.login-container h1 {
    margin-bottom: 30px;
    font-size: 28px;
    color: #ffffff;
    text-align: center;
    border-bottom: 2px solid #6200ea;
    padding-bottom: 10px;
}

.login-container label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    color: #aaaaaa;
}

.login-container input[type="text"],
.login-container input[type="password"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 25px;
    border: 1px solid #333;
    border-radius: 6px;
    background-color: #2c2c2c;
    color: #e0e0e0;
    font-size: 16px;
}

.login-container input[type="text"]::placeholder,
.login-container input[type="password"]::placeholder {
    color: #777;
}

.login-container input[type="text"]:focus,
.login-container input[type="password"]:focus {
    border-color: #6200ea;
    outline: none;
}

.login-container button {
    width: 100%;
    padding: 14px;
    border: none;
    border-radius: 6px;
    background-color: #6200ea;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-container button:hover {
    background-color: #3700b3;
}
